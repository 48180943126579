import React from "react";
import moment from "moment";
import { change, Field, reduxForm } from "redux-form";
import {
  Alert,
  Button,
  DatePicker,
  TimePicker,
  Tooltip,
  Checkbox as ACheckbox,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Input from "../../../../form-components/Input";
import Checkbox from "../../../../form-components/Checkbox";
import { makeField } from "../../../../form-components/makeComponent";
import CustomSelect from "../../../../form-components/CustomSelect";
import slugify from "../../../../../helpers/slugify";
import { linkRepertoireIntegration } from "../../../../../theme-variables";

const ARangePicker = makeField(DatePicker.RangePicker, "DD.MM.YYYY");
const ATimeRangePicker = makeField(TimePicker.RangePicker, "HH:mm");

class EventForm extends React.Component {
  state = {
    fullDay: this.props.initialValues.full_day,
    enableTickets: !this.props.initialValues.enable_tickets,
    chatEnabled:
      this.props.initialValues.vimeo_chat !== null &&
      this.props.initialValues.vimeo_chat !== "" &&
      this.props.initialValues.vimeo_chat !== undefined,
  };
  onSubmit = (formValues) => {
    formValues.enable_tickets = !formValues.enable_tickets;
    this.props.onSubmit(formValues);
  };
  handleVideoChange = (e) => {
    this.props.change("title", e.target.options[e.target.selectedIndex].text);
    if (!this.props.initialValues.slug)
      this.props.dispatch(
        change(
          "eventForm",
          "slug",
          slugify(e.target.options[e.target.selectedIndex].text).substr(0, 40)
        )
      );
  };
  onDateRangeChange = (value, dateString) => {
    if (value !== null) {
      this.props.change("date_from", value[0].format());
      this.props.change("date_to", value[1].format());
    } else {
      this.props.change("date_from", null);
      this.props.change("date_to", null);
    }
  };
  onTimeRangeChange = (value, dateString) => {
    if (value !== null) {
      this.props.change("time_from", value[0].format("HH:mm:ss"));
      this.props.change("time_to", value[1].format("HH:mm:ss"));
    } else {
      this.props.change("time_from", null);
      this.props.change("time_to", null);
    }
  };
  onFulldayChange = (e) => {
    this.setState({ fullDay: e.target.checked });
  };
  onEnableTicketsChange = (e) => {
    this.setState({ enableTickets: !e.target.checked });
  };
  toggleChat = (e) => {
    this.setState({ chatEnabled: e.target.checked });
    if (!e.target.checked) this.props.change("vimeo_chat", null);
  };
  render() {
    const error = this.props.error;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <label className="video-select" htmlFor="selectVideo">
          {this.props.syncErrors && this.props.syncErrors.video_id
            ? "ERROR"
            : null}
          <Field
            onChange={this.handleVideoChange}
            id="selectVideo"
            name="video_id"
            label="Wybierz wideo"
            component={CustomSelect}
          >
            <option></option>
            {this.props.videos.map((v) => (
              <option key={v.id} value={v.id}>
                {v.title}
              </option>
            ))}
          </Field>
        </label>
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł wydarzenia (wymagane)"
          onChange={(e) => {
            if (!this.props.initialValues.slug)
              this.props.dispatch(
                change(
                  "eventForm",
                  "slug",
                  slugify(e.target.value).substr(0, 40)
                )
              );
          }}
          type="text"
        />
        <Field
          name="slug"
          component={Input}
          placeholder="Odnośnik"
          type="text"
          suffix={
            <Tooltip title="Proszę wpisać końcową wartość odnośnika do zawartości, np. tytul-wydarzenia (wydarzenie będzie dostępne za pomocą odnośnika: /wydarzenie/tytul-spektaklu). Prosimy nie modyfikować wartości, jeżeli odnośnik do wydarzenia został udostępniony. Zmiana wartości spowoduje, że poprzedni odnośnik będzie nieaktywny.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="subtitle"
          component={Input}
          placeholder="Podtytuł"
          type="text"
        />
        <Field
          name="event_price"
          component={Input}
          placeholder="Cena"
          suffix={" zł"}
          type="number"
          step="0.01"
          style={{ width: "150px" }}
        />
        <Field
          name="available_tickets"
          component={Input}
          label="Liczba dostępnych biletów"
          type="number"
          step="1"
          style={{ width: "250px" }}
          suffix={
            <Tooltip
              style={{ margin: "0 8px", color: "blue" }}
              title="Proszę podać pustą wartośc jeżeli liczba dostępnych biletów jest nieograniczona"
            >
              <InfoCircleOutlined className="site-form-item-icon" />
            </Tooltip>
          }
        />
        <Field
          name="enable_tickets"
          component={Checkbox}
          type="checkbox"
          label="Zablokuj płatności"
          size="big"
          onChange={this.onEnableTicketsChange}
          suffix={
            <Tooltip title="W przypadku zaznaczenia tej opcji przycisk Kup dostęp / Uzyskaj dostęp nie będzie dostępny dla użykowników. W ciągu dalszym będzie mona przyznawać dostęp za pomocą formularza na stronie wydarzenia.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        {!this.state.enableTickets ? (
          <Field
            name="enable_codes"
            component={Checkbox}
            type="checkbox"
            label="Zezwól na wykorzystywanie kodów rabatowych"
            size="big"
            suffix={
              <Tooltip title="Użytkownicy posiadający kod rabatowy (np. -100%), będą mogli uzyskać dostęp / zrealizować płatność mimo wyłączenia płatności.">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
          />
        ) : null}
        <br />
        <Field
          label="Zakres dat"
          name="rangepicker"
          component={ARangePicker}
          placeholder={["Od", "Do"]}
          hasFeedback
          onChange={this.onDateRangeChange}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />

        <Field
          name="full_day"
          component={Checkbox}
          type="checkbox"
          label="Dostępny cały dzień"
          onChange={this.onFulldayChange}
          size="big"
        />
        {!this.state.fullDay ? (
          <Field
            label="Zakres godzin"
            name="timerangepicker"
            component={ATimeRangePicker}
            placeholder={["Od", "Do"]}
            hasFeedback
            onChange={this.onTimeRangeChange}
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
          />
        ) : null}
        <Field
          name="hide_time_to"
          component={Checkbox}
          type="checkbox"
          label="Ukryj godzinę zakończenia"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli godzina zakończenia ma być ukryta w sekcji Repertuar oraz na stronie wydarzenia">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="is_festival"
          component={Checkbox}
          type="checkbox"
          label="Wydarzenie festiwalowe"
          suffix={
            <Tooltip
              style={{ margin: "0 8px", color: "blue" }}
              title="Wydarzenie festiwalowe nie wyświetli się w standardowym widoku repertuaru. Aby skonfigurować festiwal, przejdź do zakładki Festiwal"
            >
              <InfoCircleOutlined
                className="site-form-item-icon"
                style={{ color: "rgba(0,0,0,.45)" }}
              />
            </Tooltip>
          }
          size="big"
        />
        <Field
          name="dashboard_hidden"
          component={Checkbox}
          type="checkbox"
          label="Ukryty na stronie głównej"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli wydarzenie ma być ukryte w sekcji Repertuar/Festiwal na stronie głównej">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <ACheckbox
          style={{ fontSize: "13px", margin: "5px 0" }}
          checked={this.state.chatEnabled}
          size="big"
          onChange={this.toggleChat}
        >
          Włącz czat
          <Tooltip title="Czat pojawi się pod wideo w trakcie oglądania. Aby wygenerować kod osadzenia nalezy utworzyć wydarzenia na stronie Vimeo (wymagane Vimeo Premium lub Vimeo Enterprise).">
            <InfoCircleOutlined
              style={{ color: "rgba(0,0,0,.45)", marginLeft: 5 }}
            />
          </Tooltip>
        </ACheckbox>
        {this.state.chatEnabled ? (
          <Field
            id="vimeoChat"
            name="vimeo_chat"
            component={Input}
            placeholder="Kod osadzenia czatu"
            type="text"
            suffix={
              <Tooltip title="Umieść kod iframe. Kod osadzenia można uzyskać przy tworzeniu wydarzenia w zakładce Live Events (wymagane konto Vimeo Premium lub Vimeo Enterprise).">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
          />
        ) : null}
        {linkRepertoireIntegration ? (
          <Field
            name="share_teatrlink"
            component={Checkbox}
            type="checkbox"
            label="Udostępnij w teapp.link"
            size="big"
            suffix={
              <Tooltip title="Zaznacz jeżeli chcesz udostępnić w wydarzenie na stronie teapp.link. Skontaktuj się z nami jeżeli chcesz zintegrować Twój system VOD z systemem teapp.link.">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
          />
        ) : null}
        <Field
          name="auth_required"
          component={Checkbox}
          type="checkbox"
          label="Dostępne tylko dla zalogowanych użytkowników"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli wydarzenie ma być dostępne tylko dla zalogowanych użytkowników. Jeżeli opcja jest odznaczona oraz dostęp do treści jest darmowy to użytkownicy niezalogowani będa mogli obejrzeć wideo">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="mailing_free"
          component={Checkbox}
          type="checkbox"
          label="Darmowy dostęp dla użytkowników zapisanych do newslettera"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli wydarzenie ma być dostępne za darmo dla użytkowników, którzy zapisali się do newslettera. Jeżeli wydarzenie jest za darmo to zaznaczenie tej opcji spowoduje, że użytkownicy niezapisani do newslettera nie będą mieli dostępu.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="published"
          component={Checkbox}
          type="checkbox"
          label="Opublikowany"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli wydarzenie ma być dostępne dla użytkowników. Pole odznaczone oznacza, że dostęp do wideo będzie miał tylko administrator">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        {error ? <Alert message={error} type="error" showIcon /> : null}
        <br />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>
      </form>
    );
  }
}

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.title) errors.title = "Proszę podać tytuł.";
  if (!formValues.slug) errors.slug = "Proszę podać odnośnik.";

  if (
    formValues.slug !== undefined &&
    props.eventSlugs.find(
      ({ slug, id }) => slug === formValues.slug && props.eventId !== id
    )
  )
    errors.slug = "Odnośnik zajęty";

  if (formValues.slug !== undefined && formValues.slug.length > 40)
    errors.slug = "Maksymalnie 40 znaków";

  if (formValues.subtitle && formValues.subtitle.length > 200)
    errors.subtitle = "Maksymalnie 200 znaków.";

  if (!formValues.video_id) errors.video_id = "Proszę wybrać wideo.";
  if (
    formValues.event_price !== undefined &&
    !/^\d+(.\d{1,2})?$/.test(formValues.event_price)
  )
    errors.event_price = "Niepoprawna wartość";

  if (!formValues.date_from || !formValues.date_to)
    errors.rangepicker = "Niepoprawny zakres.";
  if (!formValues.time_from || !formValues.time_to)
    errors.timerangepicker = "Niepoprawny zakres.";

  if (moment(formValues.date_from).isAfter(moment(formValues.date_to)))
    errors.rangepicker = "Niepoprawna data";

  if (
    moment(formValues.time_from, "HH:mm").isAfter(
      moment(formValues.time_to, "HH:mm")
    )
  )
    errors.timerangepicker = "Niepoprawne godziny";

  return errors;
};

export default reduxForm({ form: "eventForm", validate })(EventForm);
